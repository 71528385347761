<!--升级认证发布人-->
<template>
    <div class="page">
        <div class="my-top">
            <div class="insert-picture">
                <div class="pic-content">
                  <van-uploader :deletable="true" v-model="fileList" :after-read="afterRead" @delete="deleteImg" :max-count="1"/>
                    <div class="info-right">
                        <van-field v-model="form.name" required name="真实姓名" label="真实姓名" v-bind:disabled="(form.userState=='SUCCESS'||form.businessState=='SUCCESS')"
                                placeholder="请输入真实姓名" :rules="[{ required: true, message: '请输入真实姓名' }]"
                        />
                        <van-field v-model="form.age" required name="年龄" label="年龄" placeholder="年龄"
                                :rules="[{ required: true, message: '请填写年龄' }]"
                        />
                    </div>
                </div>
                <div>
                    <van-field v-model="form.address" required
                            clearable readonly  @click="showArea=true" size="large" label="所在城市" placeholder="请填写目前生活城市"
                            :rules="[{ required: true, message: '请填写地址' }]"
                    />
                </div>
                <div>
                  <van-field v-model="form.phone" clearable size="large" required label="手机号"  placeholder="请填写手机号" type="tel" maxlength="11"
                             :rules="[{ required: true, message: '请填写手机号' }]"
                  />
                </div>
              <div>
                <van-field v-model="form.cardNo" clearable size="large" required label="身份证号码"  placeholder="请填写身份证号码" maxlength="18"
                           v-bind:disabled="(form.businessState=='SUCCESS')"
                           :rules="[{ required: true, message: '请填写身份证号码' }]"
                />
<!--                <van-field v-model="form.cardNo" clearable size="large" required label="身份证号码"  placeholder="请填写身份证号码" maxlength="18"-->
<!--                           v-if="!form.cardNo"-->
<!--                           :rules="[{ required: true, message: '请填写身份证号码' }]"-->
<!--                />-->
<!--                <van-field v-model="form.cardNo" clearable size="large" required label="身份证号码"  placeholder="请填写身份证号码" maxlength="18"-->
<!--                           v-bind:disabled="(form.userState=='SUCCESS'||form.businessState=='SUCCESS')"-->
<!--                           v-else-->
<!--                           :rules="[{ required: true, message: '请填写身份证号码' }]"-->
<!--                />-->
              </div>
              <div>
                <van-field v-model="form.prove" clearable size="large" label="证明人"  placeholder="请填写证明人"
                           :rules="[{ required: true, message: '请填写证明人' }]"
                />
              </div>
              <div>
                <van-field v-model="form.enterprise" clearable size="large" label="合作厂家"  placeholder="请填写合作厂家"
                           :rules="[{ required: true, message: '请填写合作厂家' }]"
                />
              </div>
              <!--收款码-->
<!--              <div class="CheckGroupBox">-->
<!--                <label class="payImgLabel">收款码</label>-->
<!--                <van-uploader :deletable="true" v-model="payImgList" :after-read="afterReadPayImg" @delete="deletePayImg" :max-count="1"/>-->
<!--              </div>-->
                <div class="basic-info">
                    <van-button :disabled="submitDisabled" round block type="info" native-type="submit" class="save-btn" @click="save">保存信息</van-button>
                </div>
            </div>
        </div>

      <van-dialog v-model="showArea" title="" :show-cancel-button="false" :showConfirmButton="false">
        <van-area title="选择地区" :area-list="areaList" @confirm="confirmArea" />
      </van-dialog>
        <!-- 弹出选择 -->
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="onCancel"
                    :default-index="0"
                    cancel-button-text="清除已选"
            />
        </van-popup>
      <van-dialog v-model="showSuccess" title="提交成功，客服审核中" style="text-align: center" @confirm="go()">
        <div style="font-size: 16px;width: 100%;text-align: center;">结果,将通过“活可多”公众号发送给您</div>
        <div style="font-size: 16px;width: 100%;text-align: center;">请长按下方二维码，关注“活可多”公众号</div>
        <img width="280px" src="@/assets/images/qrcode.jpg" />
      </van-dialog>
    </div>


</template>

<script>
    import {        upload,      editUserB,      me    } from "../../api/login";
    import {        Notify,        ActionSheet    } from 'vant';
    import store from '../../store'
    import {        mapMutations    } from 'vuex'
    import {        SET_USER    } from '../../const.js'
    import { areaList } from '../../api/area';
    import {compressImage} from "../../utils/CompressImageUtils";
    export default {
        data() {
            return {
              submitDisabled: false,
              showSuccess:false,
              areaList: areaList,
              showArea:false,
              form: {
                id: undefined,
                avatar: undefined,
                gender: undefined,
                phone: undefined,
                name: undefined,
                address: undefined,
                advantage: undefined,
                age: undefined,
                cardBack: undefined,
                cardJust: undefined,
                certificate: undefined,
                insurance: undefined,
                workAge: undefined,
                cardNo: undefined,
                workType: [],
                enterprise:undefined,
                prove: undefined,
                payImg: undefined
              },
              fileList: [],//头像
              payImgList: [],//收款码
              certificateList:[],//资格证书
              cardList:[],//身份证
                insurance: undefined,
                showPicker: false,
                columns: ["是", "否"],
            }
        },
        mounted() {
          let user = JSON.parse(`${store.state.user}`)
          console.info("load user",user)
          this.fileList.push({url:user.avatar,file:new File([],'',{})})
          if (user.payImg){
            this.payImgList.push({url:user.payImg,file:new File([],'',{})})
          }
          // if (user.businessState!='SUCCESS'){
          //   this.form.id=user.id
          // }else{
            this.form=user
          // }

        },
        methods: {
          ...mapMutations([SET_USER]),
          confirmArea(e){
            let sheng = e[0];
            let city = e[1];
            let qu = e[2];
            this.form.address = sheng.name +" "+city.name+" "+qu.name
            this.showArea=false;
          },
            onConfirm(value) {
                this.insurance = value;
                this.showPicker = false;

            },
            onCancel() {
                this.insurance = undefined;
                this.showPicker = false;
            },

          deleteImgCardListBefore(e,name){
            if (name.index==0){
              this.form.cardJust="";
            }else{
              this.form.cardBack="";
            }
            this.cardList.splice(name.index,1)
          },
          async afterReadCard(file) {
            const forms = new FormData();
            forms.append("file", file.file); // 获取上传图片信息
            let r = await upload(forms)
            if (this.cardList.length==1){
              this.form.cardJust=r.data.url
            }else {
              this.form.cardBack=r.data.url
            }
          },

          deletePayImg(){
            console.info("deleteImg", )
            this.payImgList= []
            this.form.payImg='';
          },
          async afterReadPayImg(file) {
            compressImage(file.content).then(result => {
              console.log('压缩后的结果', result); // result即为压缩后的结果
              console.log('压缩前大小', file.file.size);
              console.log('压缩后大小', result.size);
              if (result.size > file.file.size){
                console.log('上传原图');
                //压缩后比原来更大，则将原图上传
                this._uploadPayImg(file.file,file.file.name);
              } else {
                //压缩后比原来小，上传压缩后的
                console.log('上传压缩图');
                this._uploadPayImg(result,file.file.name)
              }
            })
          },
          async _uploadPayImg(file,filename){
            const forms = new FormData();
            forms.append("file", file,filename); // 获取上传图片信息
            let r = await upload(forms)
            this.form.payImg = r.data.url
            console.info("payImg Upload:",this.payImgList)
          },

          deleteImg(){
            console.info("deleteImg", )
            this.fileList= []
            this.form.avatar='';
          },
          async afterRead(file) {
            const forms = new FormData();
            forms.append("file", file.file); // 获取上传图片信息
            let r = await upload(forms)
            this.form.avatar = r.data.url
          },
          async save() {

            console.info("save",this.form)
            const idCardReg = /^[1-9]\d{5}(19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i; // 身份证格式正则表达式
            if (!this.form.name) {Notify({type: 'warning',message: '请输入真实姓名'});return false;}
            if (!this.form.address) {Notify({type: 'warning',message: '请选择地址'});return false;}
            if (!this.form.phone) {Notify({type: 'warning',message: '请输入手机号'});return false;}
            if(!(/^1[3456789]\d{9}$/.test(this.form.phone))){
              Notify({type: 'warning',message: '手机号格式错误'});return false;
            }
            if (!this.form.cardNo) {Notify({type: 'warning',message: '请输入身份证号码'});return false;}
            if (!this.form.cardNo.match(idCardReg)) {Notify({type: 'warning',message: '请输入正确的身份证号码'});return false;}
            // if (this.payImgList.length<1){Notify({type: 'warning',message: '请上传收款码'});return false;}
            this.submitDisabled=true;
            let r = await editUserB(this.form)
            this.submitDisabled=false;
            if (r.errno!=0){
              Notify({ type: 'error', message: r.errmsg });
            }else {
              console.info("保存成功",r)
              this.setUser(JSON.stringify(r.data))
              this.showSuccess=true;
            }
          },
          go(){
            // this.showSuccess=true;
            if(this.back){
              history.back();
            }else{
              this.$router.push({
                path: '/home'
              })
            }
          }

        },
        computed: {}
    }

</script>

<style lang="less" scoped>

.payImgLabel::before {
  position: absolute;
  padding-top: 6px;
  left: 8px;
  color: #ee0a24;
  font-size: 14px;
  content: '*';
}
.CheckGroupBox{
  display: flex;
  padding: 15px 5px 5px 15px;
  label{
    padding-top: 5px;
    color: #646566;
    text-align: left;
    word-wrap: break-word;
    width: 5.3em;
    font-size: 16px;
  }
}

    .my-top {
        height: 120px;
        width: 100%;
        background: linear-gradient(to right, #1083FC, #08ADFD);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;

        .insert-picture {
            max-width: 700px;
            border-radius: 7px;
            width: 94%;
            margin: 0;
            //height: 100%;
            min-height: 560px;
            background: #FFFFFF;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 30px);

            .pic-content {
                margin-top: 15px;
                margin-left: 10px;
                display: flex;
                justify-content: space-around;

                .info-right {
                    width: 70%;
                }

            }
            .basic-info {
                position: fixed;
                bottom: 10px;
                max-width: 720px;
                width: 100%;
            }
        }
    }




</style>
